<template>
    <Overlay @fechaOverlay="$emit('fechaOverlay')">
        <Card>
            <form class="form" @submit.prevent="salvaEdicao()">
                <div>
                    <label for="loja">Loja:</label>
                    <input type="number" id="loja" name="loja" step="1" v-model="editLoja" disabled />
                </div>

                <div :class="{ error: nomeInvalido }">
                    <label for="nome">Nome:</label>
                    <input type="text" id="nome" name="nome" v-model="editNome" />
                </div>

                <div :class="{ error: regionalInvalida }">
                    <label for="regional">Regional:</label>
                    <input type="text" id="regional" name="regional" v-model="editRegional" />
                </div>

                <div>
                    <label for="tipo">Tipo:</label>
                    <select name="tipo" id="tipo" @change="tipoHandler">
                        <option value="Varejo" :selected="editTipo == 'Varejo'">Varejo</option>
                        <option value="Atacarejo" :selected="editTipo == 'Atacarejo'">Atacarejo</option>
                    </select>
                </div>

                <div>
                    <label for="quantidade_tarefas">Qtd. Tarefas:</label>
                    <input type="number" id="quantidade_tarefas" name="quantidade_tarefas" step="1" v-model="editQuantidadeTarefa" />
                </div>

                <div>
                    <label for="quantidade_alertas">Qtd. Alertas:</label>
                    <input type="number" id="quantidade_alertas" name="quantidade_alertas" step="1" v-model="editQuantidadeAlerta" />
                </div>

                <small class="form_error-msg" v-if="regionalInvalida">Regional não pode estar vazio.</small>
                <small class="form_error-msg" v-if="nomeInvalido">Nome não pode ter menos de 4 caracteres</small>

                <Button texto="Salvar" />
            </form>
        </Card>
    </Overlay>
</template>

<script>
import Overlay from '../UI/Overlay.vue';
import Card from '../UI/Card.vue';
import Button from '../UI/Button.vue';

export default {
    components: {
        Overlay,
        Card,
        Button,
    },

    props: {
        nome: {
            required: true,
        },
        loja: {
            required: true,
        },
        regional: {
            required: true,
        },
        tipo: {
            required: true,
        },
        quantidade_tarefas: {
            required: true,
        },
        quantidade_alertas: {
            required: true,
        },
    },

    data() {
        return {
            regionalInvalida: false,
            nomeInvalido: false,
            editNome: '',
            editLoja: '',
            editRegional: '',
            editTipo: '',
            editQuantidadeTarefa: '',
            editQuantidadeAlerta: '',
        };
    },

    created() {
        this.editNome = this.nome;
        this.editLoja = this.loja;
        this.editRegional = this.regional;
        this.editTipo = this.tipo;
        this.editQuantidadeTarefa = this.quantidade_tarefas;
        this.editQuantidadeAlerta = this.quantidade_alertas;
    },

    watch: {
        editNome(newValue) {
            if (newValue.trim().length > 4) {
                this.nomeInvalido = false;
            }
        },
        editRegional(newValue) {
            if (newValue != '') {
                this.regionalInvalida = false;
            }
        },
    },

    methods: {
        salvaEdicao() {
            if (this.validaEdicao() == false) return;

            const loja = {
                nome: this.editNome,
                loja: this.editLoja,
                regional: this.editRegional,
                tipo: this.editTipo,
                quantidade_tarefas: this.editQuantidadeTarefa,
                quantidade_alertas: this.editQuantidadeAlerta,
            };

            this.$emit('salvaEdicao', loja);
        },

        validaEdicao() {
            if (this.editNome.trim().length <= 4) {
                this.nomeInvalido = true;
                return false;
            }

            if (this.editRegional == '') {
                this.regionalInvalida = true;
                return false;
            }

            return true;
        },

        tipoHandler(event) {
            this.editTipo = event.target.value;
        },
    },
};
</script>

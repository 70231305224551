<template>
    <Card>
        <div class="info">
            <div class="info_gap">
                <strong>Loja:</strong>
                <span>{{ loja }}</span>
            </div>

            <div class="info_gap">
                <strong>Regional:</strong>
                <span>{{ regional }}</span>
            </div>

            <div class="info_gap">
                <strong>Nome:</strong>
                <span>{{ nome }}</span>
            </div>

            <div class="info_gap">
                <strong>Tipo:</strong>
                <span>{{ tipo }}</span>
            </div>

            <div class="info_gap">
                <strong>Quantidade Tarefas:</strong>
                <span>{{ quantidade_tarefas }}</span>
            </div>

            <div class="info_gap">
                <strong>Quantidade Alertas:</strong>
                <span>{{ quantidade_alertas }}</span>
            </div>

            <Button texto="Editar" @click="editarLoja()" />
        </div>
    </Card>
</template>

<script>
import Card from '../UI/Card.vue';
import Button from '../UI/Button.vue';

export default {
    components: {
        Card,
        Button,
    },

    props: {
        nome: {
            required: true,
        },
        loja: {
            required: true,
        },
        regional: {
            required: true,
        },
        tipo: {
            required: true,
        },
        quantidade_tarefas: {
            required: true,
        },
        quantidade_alertas: {
            required: true,
        },
    },

    methods: {
        editarLoja() {
            const loja = {
                loja: this.loja,
                nome: this.nome,
                regional: this.regional,
                tipo: this.tipo,
                quantidade_tarefas: this.quantidade_tarefas,
                quantidade_alertas: this.quantidade_alertas,
            };

            this.$emit('editarLoja', loja);
        },
    },
};
</script>

<style lang="scss" scoped>
.info {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &_gap {
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }
}
</style>

<template>
    <Overlay @fechaOverlay="$emit('fechaOverlay')">
        <Card>
            <form class="form" @submit.prevent="salvaNovaLoja()">
                <div :class="{ error: lojaInvalida }">
                    <label for="loja">Loja:</label>
                    <input type="number" id="loja" name="loja" step="1" v-model="loja" />
                </div>

                <div :class="{ error: nomeInvalido }">
                    <label for="nome">Nome:</label>
                    <input type="text" id="nome" name="nome" v-model="nome" />
                </div>

                <div :class="{ error: regionalInvalida }">
                    <label for="regional">Regional:</label>
                    <input type="number" id="regional" name="regional" step="1" v-model="regional" />
                </div>

                <div>
                    <label for="tipo">Tipo:</label>
                    <select name="tipo" id="tipo" @change="tipoHandler">
                        <option value="Varejo">Varejo</option>
                        <option value="Atacarejo">Atacarejo</option>
                    </select>
                </div>

                <div>
                    <label for="quantidade_tarefas">Qtd. Tarefas:</label>
                    <input type="number" id="quantidade_tarefas" name="quantidade_tarefas" step="1" v-model="quantidade_tarefas" />
                </div>

                <div>
                    <label for="quantidade_alertas">Qtd. Alertas:</label>
                    <input type="number" id="quantidade_alertas" name="quantidade_alertas" step="1" v-model="quantidade_alertas" />
                </div>

                <small class="form_error-msg" v-if="lojaInvalida">Loja não pode estar vazio.</small>
                <small class="form_error-msg" v-if="regionalInvalida">Regional não pode estar vazio.</small>
                <small class="form_error-msg" v-if="nomeInvalido">Nome não pode ter menos de 4 caracteres</small>

                <Button texto="Salvar" />
            </form>
        </Card>
    </Overlay>
</template>

<script>
import Overlay from '../UI/Overlay.vue';
import Card from '../UI/Card.vue';
import Button from '../UI/Button.vue';

export default {
    components: {
        Overlay,
        Card,
        Button,
    },

    data() {
        return {
            nome: '',
            loja: '',
            regional: '',
            tipo: 'Varejo',
            quantidade_tarefas: '',
            quantidade_alertas: '',
            regionalInvalida: false,
            lojaInvalida: false,
            nomeInvalido: false,
        };
    },

    watch: {
        nome(newValue) {
            if (newValue.trim().length > 4) {
                this.nomeInvalido = false;
            }
        },
        loja(newValue) {
            if (newValue != '') {
                this.lojaInvalida = false;
            }
        },
        regional(newValue) {
            if (newValue != '') {
                this.regionalInvalida = false;
            }
        },
    },

    methods: {
        salvaNovaLoja() {
            if (this.validaCriacao() == false) return;

            const loja = {
                nome: this.nome,
                loja: this.loja,
                regional: this.regional,
                tipo: this.tipo,
                quantidade_tarefas: this.quantidade_tarefas,
                quantidade_alertas: this.quantidade_alertas,
            };

            this.$emit('salvaNovaLoja', loja);
        },

        validaCriacao() {
            if (this.nome.trim().length <= 4) {
                this.nomeInvalido = true;
                return false;
            }

            if (this.loja == '') {
                this.lojaInvalida = true;
                return false;
            }

            if (this.regional == '') {
                this.regionalInvalida = true;
                return false;
            }

            return true;
        },

        tipoHandler(event) {
            this.tipo = event.target.value;
        },
    },
};
</script>

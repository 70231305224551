<template>
    <div class="container">
        <Title titulo="Lojas" />

        <div class="navigate">
            <SearchInput texto="Pesquisar loja" @changeInput="searchText" />

            <Button texto="Nova Loja" @click="isCreating = true" />
        </div>

        <Spinner v-if="isLoading" />

        <div class="lojas" v-else>
            <transition-group name="fade" mode="out-in">
                <template v-if="lojasFiltradas.length > 0">
                    <LojaItem
                        v-for="loja in lojasFiltradas"
                        :key="loja.loja"
                        :nome="loja.nome"
                        :loja="loja.loja"
                        :regional="loja.regional"
                        :tipo="loja.tipo"
                        :quantidade_tarefas="loja.quantidade_tarefas"
                        :quantidade_alertas="loja.quantidade_alertas"
                        @editarLoja="editarLoja"
                    />
                </template>

                <template v-else>
                    <LojaItem
                        v-for="loja in todasLojas"
                        :key="loja.loja"
                        :nome="loja.nome"
                        :loja="loja.loja"
                        :regional="loja.regional"
                        :tipo="loja.tipo"
                        :quantidade_tarefas="loja.quantidade_tarefas"
                        :quantidade_alertas="loja.quantidade_alertas"
                        @editarLoja="editarLoja"
                    />
                </template>
            </transition-group>
        </div>

        <transition name="fade" mode="out-in">
            <EditaLoja
                v-if="isEditing"
                @fechaOverlay="fechaOverlay()"
                @salvaEdicao="salvaEdicao"
                :nome="nome"
                :loja="loja"
                :tipo="tipo"
                :quantidade_tarefas="quantidade_tarefas"
                :quantidade_alertas="quantidade_alertas"
                :regional="regional"
            />
        </transition>

        <transition name="fade" mode="out-in">
            <NovaLoja v-if="isCreating" @fechaOverlay="fechaOverlay()" @salvaNovaLoja="salvaNovaLoja" />
        </transition>

        <Modal v-if="showModal" :isError="isError" :mensagem="modalMessage" @closeModal="showModal = false" />
    </div>
</template>

<script>
import Title from '../components/UI/Title.vue';
import Button from '../components/UI/Button.vue';
import Modal from '../components/Modal.vue';
import SearchInput from '../components/SearchInput.vue';
import LojaItem from '../components/Lojas/LojaItem.vue';
import NovaLoja from '../components/Lojas/NovaLoja.vue';
import EditaLoja from '../components/Lojas/EditaLoja.vue';
import Spinner from '../components/UI/Spinner.vue';

export default {
    components: {
        Title,
        SearchInput,
        Button,
        Modal,
        Spinner,
        LojaItem,
        NovaLoja,
        EditaLoja,
    },

    data() {
        return {
            nome: '',
            regional: '',
            loja: '',
            tipo: '',
            quantidade_tarefas: '',
            quantidade_alertas: '',
            isEditing: false,
            isCreating: false,
            todasLojas: [],
            lojasFiltradas: [],
            showModal: false,
            isError: false,
            modalMessage: '',
            user: null,
            isLoading: true,
            headers: null,
        };
    },

    created() {
        this.user = JSON.parse(sessionStorage.getItem('user'));
        this.headers = { Accept: 'application/json', authorization: 'Bearer ' + this.user.token };

        this.axios
            .get(`${this.user.cliente}/lojas`, { headers: this.headers })
            .then((res) => {
                this.todasLojas = res.data;
            })
            .catch(() => {
                this.isError = true;
                this.showModal = true;
                this.modalMessage =
                    'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    beforeRouteEnter(to, from, next) {
        const permissoes = JSON.parse(sessionStorage.getItem('permissoes'));

        if (permissoes.permissoes.tela_lojas == 0) {
            next({ path: '/usuarios' });
        } else {
            next();
        }
    },

    methods: {
        editarLoja(loja) {
            this.nome = loja.nome;
            this.regional = loja.regional;
            this.loja = loja.loja;
            this.tipo = loja.tipo;
            this.quantidade_tarefas = loja.quantidade_tarefas;
            this.quantidade_alertas = loja.quantidade_alertas;

            this.isEditing = true;
        },

        salvaEdicao(loja) {
            this.axios
                .patch(`${this.user.cliente}/lojas`, loja, { headers: this.headers })
                .then(() => {
                    const existingLojaIndex = this.todasLojas.findIndex((item) => item.loja == loja.loja);

                    let updatedLojas = [...this.todasLojas];
                    updatedLojas[existingLojaIndex] = loja;

                    this.todasLojas = updatedLojas;

                    this.isError = false;
                    this.modalMessage = 'Loja editada com sucesso.';
                })
                .catch(() => {
                    this.isError = true;
                    this.modalMessage =
                        'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
                })
                .finally(() => {
                    this.isEditing = false;
                    this.showModal = true;
                });
        },

        salvaNovaLoja(loja) {
            this.axios
                .post(`${this.user.cliente}/lojas`, loja, { headers: this.headers })
                .then(() => {
                    this.todasLojas.unshift(loja);

                    this.isError = false;
                    this.modalMessage = 'Loja cadastrada com sucesso.';
                })
                .catch(() => {
                    this.isError = true;
                    this.modalMessage =
                        'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
                })
                .finally(() => {
                    this.isCreating = false;
                    this.showModal = true;
                });
        },

        fechaOverlay() {
            this.isEditing = false;
            this.isCreating = false;
            this.showModal = false;
        },

        searchText(text) {
            this.lojasFiltradas = [];

            if (text.trim().length > 0) {
                this.lojasFiltradas = this.todasLojas.filter((loja) => {
                    return loja.loja.toString().includes(text);
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.navigate {
    display: grid;
    gap: 30px;
    margin-bottom: 50px;

    @media (min-width: 768px) {
        gap: 50px;
        grid-template-columns: 350px 150px 150px;
    }
}

.lojas {
    display: grid;
    gap: 30px;

    @media (min-width: 768px) {
        gap: 30px;
        justify-content: space-between;
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1600px) {
        gap: 50px;
        grid-template-columns: repeat(4, 1fr);
    }
}
</style>
